.menu-open {
    display: block;
    position: absolute;
    top: 18px;
    right: 16px;
    width: 44px;
    height: 44px;
    background-image: url('../img/menu-open.png');
    display: none;
}

.menu-close {
    display: block;
    position: absolute;
    top: 18px;
    right: 16px;
    width: 44px;
    height: 44px;
    background-image: url('../img/menu-close.png');
}

.icons-menu {
    width: 30px;
    height: 24px;
    background-repeat: no-repeat;
    background-position-x: right;
    margin: 0px 16px;
}

.icons-menu.folder {
    width: 30px;
    height: 24px;
    background-repeat: no-repeat;
    background-position-x: right;
    margin: 0px 16px;
    display: inline-block;
}

.icon-home-wire {
    background-image: url('icons/icon-home-wire.png');
}

.icon-horn-wire {
    background-image: url('icons/icon-horn-wire.png');
}

.icon-chain-wire {
    background-image: url('icons/icon-chain-wire.png');
}

.icon-gear-wire {
    background-image: url('icons/icon-gear-wire.png');
}

.icon-chart-wire {
    background-image: url('icons/icon-chart-wire.png');
}

.icon-profile-wire {
    background-image: url('icons/icon-profile-wire.png');
}

.icon-help-wire {
    background-image: url('icons/icon-help-wire.png');
}

.icon-clipboard-wire {
    background-image: url('icons/icon-clipboard-wire.png');
}

.icon-badge-wire {
    background-image: url('icons/icon-badge-wire.png');
}

.icon-analytics-wire {
    background-image: url('icons/icon-analytics.png');
}



.contact-icons {
    display: inline-block;
    width: 16.04px;
    height:16px;
    margin-right: 9px;
    background-repeat: no-repeat;
    background-position-x: right;
}

.icon-phone-fill {
    background-image: url('icons/icon-phone-fill.png');
}

.icon-email-fill {
    background-image: url('icons/icon-email-fill.png');
}

.icon-pin-fill {
    height:38px !important;
    float:left;
    background-position-y: center;
    background-image: url('icons/icon-pin-fill.png');
}

.icon-linkedin-fill {
    background-image: url('icons/icon-linkedin-fill.png');
}

.icon-upload {
    width:24px;
    height:24px;
    background-image: url('icons/icon-upload.png');
}

.icon-inlinesupport {
    display: inline-block;
    margin:0px;
    width:1.20rem;
    height:1.20rem;
    background-size: contain;
    background-image: url('icons/icon-inlinesupport.svg');
    background-repeat: no-repeat;
    cursor: pointer;
}