@font-face { font-family: 'Lato Black'; src: url(./lato/Lato-Black.ttf); }
@font-face { font-family: 'Lato Bold'; src: url(./lato/Lato-Bold.ttf); }
@font-face { font-family: 'Lato'; src: url(./lato/Lato-Regular.ttf); }

/* ------------ STANDARD ELEMENTS ------------------ */
html {
  height: 100%;
  font-size: 16px;
}
body {
  height: 100%;
  background-color: #F1F5F9;
  font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  margin:0px;
  padding:0px;
  text-wrap: balance;
  text-wrap: pretty;
}

#root {
  height: 100%;
}
.skipnavigaton:focus {
  top:1px;
  transition: 150ms;
}
.skipnavigaton {
  position:absolute;
  top:-20px;
  left: 24px;
  color: #ffffff;
  transition: 150ms;
}
.contentStarts {
  text-decoration: none;
  display:inline-block;
}
a {
  font-weight: 700;
  color: #513AD1;
}

h1 {
  font-family: 'Lato Black', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.875em;
  font-weight: 900;
  line-height: 38px;
  text-align: left;
  color: #0F172A;
  margin-bottom: 6px;
}

h2 {
  font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.875em;
  font-weight: 700;
  line-height: 38px;
  color: #353E5A;
  margin:0px;
  padding:0px;
}

h3 {
  font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.75em;
  font-weight: 900;
  line-height: 34px;
  text-align: left;
  color: #3B235A;
  margin: 0px 0px 12px 0px;
}

h4 {
  font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #0F172A;
  margin-top: 18px;
}

h5 {
  font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 26px;
  color: #0F172A;
  margin-top: 0px;
}

header {
  height: 58px;
  background: #0F172A;
  padding: 22px 0px 0px 22px;
}

p, li {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5em;
  text-align: left;
  color:#0F172A
}

/* ------------ FORM ELEMENTS ---------------------- */

label {
  display: block;
  font-size: 0.875em;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #475569;
  width:100%;
  margin-top:20px;
}

input[type='checkbox'] {
  width: 24px;
  height: 24px;
  margin-top:20px;
  cursor: pointer;
}

input[type='text'],input[type='password'],input[type='email'],input[type='tel'] {
  width: calc(100% - 40px);
  height: 52px;
  margin-top: 4px;
  padding: 0px 20px;
  border-radius: 10px;
  border: 1px solid #94A3B8;
  font-size:1em;
  color:#000;
}

textarea {
  width: calc(100% - 40px);
  margin-top: 4px;
  padding: 20px 20px;
  border-radius: 10px;
  border: 1px solid #94A3B8;
  font-size: 1.063em;
  color:#000;
  font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

input[type='text']::placeholder,input[type='password']::placeholder {
  font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #94A3B8;
}

hr {
  width:100%;
  border: none;
  border-bottom: 1px solid #CBD5E1;
  margin: 24px 0px;
}

/* ------------ BUTTONS ---------------------------- */

input[type='submit'] {
  font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 0.875em;
  line-height: 20px;
  border-radius: 10px;
  background-color: #0F172A;
  color: #F8FAFC;
  border: 0px;
  padding: 12px 40px 12px 18px;
  background-image: url('icons/icon-save.png');
  background-repeat: no-repeat;
  background-position: 88% 50%;
  cursor: pointer;
}
input[type='submit']:focus-visible, input[type='submit'].noSave:focus-visible {
  outline: 2px solid yellow;
}

input[type='submit'].noSave {
  font-weight: 500;
  font-size: 0.875em;
  line-height: 20px;
  border-radius: 10px;
  background-color: #0F172A;
  color: #F8FAFC;
  border: 0px;
  padding: 12px 40px 12px 18px;
  background-image: url('icons/icon-submit.png');
  background-repeat: no-repeat;
  background-position: 111px 14px;
  cursor: pointer;
}

input[type='button'] {
  font-weight: 500;
  font-size: 0.875em;
  line-height: 20px;
  height: 44px;
  border-radius: 10px;
  color: #0F172A;
  border: 1px solid #0F172A;
  background-color: #fff;
  padding: 12px 18px;
  cursor: pointer;
}

input[type='color'] {
  width: 100px;
  height: 52px;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid #94A3B8;
}

.smallInput {
  width: auto !important;
  margin: 0px;
}

/* ------------ XCL ELEMENTS ----------------------- */

.headerHomeLink {
  display: block;
  width: 83px;
  height: 50px;
  position: relative;
  top: -10px;
  left: -7px;
  padding: 4px;
}

.patentMessage.login {
  display:inline-block;
  position: relative;
  bottom: 120px;
  left: 39px;
  width: 100px;
  text-align: center;
  font-size: 0.875em;
  font-weight: 700;
  padding:6px 8px;
  background-color: #AEDFE4;
  color: #0F172A;
  border-radius: 6px;
}

.xcl-logo {
  height: 50px;
  margin: 0px 0px 0px 0px;
}

.iconInline {
  vertical-align: bottom;
  margin: 0px 10px 0px 0px;
}
.version {
  display: block;
  position: absolute;
  top: 26px;
  left: 91px;
  width: 46px;
  height: 31px;
  border-radius: 6px;
  padding: 0px 0px 0px 8px;
  background: #F6FF81;
  font-size: 1em;
  font-weight: 700;
  line-height: 29px;
  text-align: left;
  color: #0F172A;
  margin: 0px 0px 0px 20px;
}

.contentOuter {
  display: block;
  /*margin: 48px auto 0px;
  max-width: 1064px;
  min-width: 700px;
  padding: 0px 5px 120px 82px;*/
  padding: 0px 42px 120px 119px;
}

.contentOuter.openNav {
  padding-left: 357px;
}

.formColumn {
  display: inline-grid;
  width: calc(50% - 12px);
}

.column2 {
  margin-left:24px;
}

.contactInfo {
  border-radius: 12px;
  background-color : #F6F0FD;
  padding: 40px 48px 40px 48px;
  margin: 40px 4px;
}

.contactInfo > div {
  font-size: 1.2em;
  margin-bottom: 36px;
  color: #353E5A;
}

.contactInfo > h2 {
  margin-bottom: 24px;
  color: #0F172A;
}

.pageTile {
  display: block;
  padding: 35px 48px;
  background-color: #fff;
  border-radius: 8px;
  margin: 40px 0px 0px 0px;
  border: 1px solid #CBD5E1;
}

.pageTile h3 {
  font-size: 1.5em;
  font-weight: 900;
  line-height: 1.875em;
  text-align: left;
  color: #0F172A;
  margin: 0px 0px 12px 0px;
}

.pageTile h5 {
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1.25em;
  color: #475569;
  margin-top: 8px;
}

.pageTile label {
  font-size: 1em;
  font-weight: 700;
  line-height: 1.25em;
  color: #0F172A;
  margin-top:24px;
}

.pageTile input::placeholder {
  color:#475569;
}

.formControls {
  display:block;
  position:fixed;
  bottom: 0px;
  left:0px;
  height: 40px;
  width: calc(100vw - 216px);
  background-color: #fff;
  box-shadow: 0px -4px 28px 0px #353E5A0D;
  padding: 24px 108px;
  z-index: 100;
  text-align: right;
}

.lightText {
  color: #64748B;
}

.hidden {
  display: none !important;
}

a.toc {
  text-decoration: none !important;
}

.toc > h3 {
  margin: 30px 0px -12px 0px;
}

.backtotop.floating {
  width: 72px;
  text-align: center;
  position:fixed;
  bottom:20px;
  right:20px;
  z-index: 200;
}

.toplink {
  font-size: 0.75em;
  text-decoration: none;
  display:block;
  padding: 4px;
  color: #fff;
  border-radius: 3px;
  background-color: #0F172A;
}


/* ------------ APP SPECIFIC DEFAULTS -------------- */

.App {
  text-align: center;
}
/* ------------ POPUPS - less load to leave them here -------------- */


.xclPop-content .confirmIcon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #FEF2F2;
  margin: 24px 12px 0px 24px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: top;
}

.xclPop-content .emailIcon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #E6F4F6;
  margin: 24px auto 0px auto;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
}

.xclPop-content .warningIcon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #FEF2F2;
  margin: 24px 12px 0px 24px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: top;
}

.xclPop-content .successIcon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #FEF2F2;
  margin: 24px auto 0px auto;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
}

.xclPop-content .upgradeIcon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #F6F0FD;
  margin: 24px 12px 0px 24px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  background-position: center;
  vertical-align: top;
}

.xclPop-content .modalContent {
  width: calc(100% - 105px);
  margin: 16px 18px 0px 0px;
  display: inline-block;
}

.xclPop-content .modalContent.information {
  width: calc(100% - 34px);
  margin: 16px 18px;
  display: inline-block;
}

.xclPop-content .modalContent.success {
  width:calc(100% - 32px);
  margin: 16px 16px 24px 16px;
  display: block;
}
.xclPop-content .modalContent.success.centered > p {
  text-align: center;
}

.xclPop-content {
  border-radius: 8px;
  background-color: #fff;
  max-width: 520px;
  min-width: 320px;
  overflow: hidden;
  font-weight: 400;
  font-size: 1em;
  padding: 0px !important;
}

.modalContent h2 {
  font-weight: 700;
  font-size: 1.125em;
  color:#0F172A;
}
.modalContent p strong {
  color: #334155;
}
.modalContent.success h2.success {
  text-align: center;
  margin-bottom: 24px;
}
.modalContent p {
  color:#64748B;
}

.modalContent p.emphasized {
  color: #0F172A;
  margin: 30px 0px;
}

.modalContent p.warning {
  font-weight: 700;
  color: #0F172A;
}

.validationInputContainer {
  background-color: #F9F9F9;
  padding: 40px 8px;
  text-align: center;
}

.validationInputContainer input[type='text'] {
  width: 10px;
  margin: 0px 2px;
}

.validationCodeDivider {
  background-color: #94A3B8;
  width: 14px;
  height: 2px;
  display: inline-block;
  vertical-align: middle;
  margin: 0px 2px;
}

.xclPop-content .controls {
  text-align: right;
  width: 100%;
  background-color: #F8FAFC;
  height:58px;
}

.xclPop-content .controls .cancelBtn {
  font-weight: 700;
  font-size: 0.75em;
  line-height: 100%;
  border-radius: 5px;
  color: #475569;
  border: 1px solid #CBD5E1;
  background-color: #FFFFFF;
  padding: 10px 12px 10px 12px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin: 12px;
}
.xclPop-content .controls .closeBtn {
  font-weight: 500;
  font-size: 0.875em;
  line-height: 100%;
  color: #00606B;
  border: none;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  display: block;
  padding: 21px 0px;
  text-align: center;
}

.xclPop-content .controls .confirmBtn {
  font-weight: 700;
  font-size: 0.75em;
  line-height: 100%;
  border-radius: 5px;
  color: #F8FAFC;
  border: none;
  background-color: #0F172A;
  padding: 10px 12px 10px 12px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin: 12px 16px 12px 0px;
}
.xclPop-content .controls .confirmBtn.disabled {
  background-color: #CBD5E1;
  color: #94A3B8;
  border: 1px solid #CBD5E1;
}
.xclPop-content .controls .confirmDeleteBtn {
  font-weight: 700;
  font-size: 0.75em;
  line-height: 100%;
  border-radius: 5px;
  color: #F8FAFC;
  border: none;
  background-color: #7F1D1D;
  padding: 10px 12px 10px 12px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin: 12px 16px 12px 0px;
}
.xclPop-content .controls .confirmDeleteBtn.disabled {
  background-color: #CBD5E1;
  color: #94A3B8;
  border: 1px solid #CBD5E1;
}
.optionButton {
  font-weight: 700;
  font-size: 0.75em;
  line-height: 100%;
  border-radius: 5px;
  color: #475569;
  border: 1px solid #CBD5E1;
  background-color: #FFFFFF;
  padding: 10px 12px 10px 12px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin: 12px 12px 12px 0px;
}
.optionButton.bestValue {
  font-weight: 700;
  font-size: 0.75em;
  line-height: 100%;
  border-radius: 5px;
  color: #475569;
  border: 1px solid #CBD5E1;
  background-color: #F6F0FD;
  padding: 10px 12px 10px 12px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin: 12px 12px 12px 0px;
}

/* ------------ My Account -------------- */
.accountBlock {
  display: block;
  padding: 20px 40px 40px 40px;
  background-color: #fff;
  border-radius: 30px;
  border-radius: 25px;
  margin: 37px 0px 0px 0px;
  box-shadow: 0px 1px 3px 0px #0000001A;
  min-width: 260px;
}
.accountBlock h4, .acCalloutBlock h4 {
  font-weight: 700;
  font-size: 1.25em;
  margin: 0px;
}
.accountDashBlock {
  margin: 0px 0px 24px 0px;
}
.accountDashBlock.internal {
  margin: 0px 40px 24px 40px;
}
.accountDashBlock h5 {
  font-size: 1.125em;
  font-weight: 700;
  margin: 0px 0px 16px 0px;
}
.accountDashBlock.internal h5 {
  font-size: 1em;
}

.accountDashContent {
  border-radius: 8px;
  border: 0.5px solid #CBD5E1;
  padding: 20px;
  background-color: #FFFFFF;
}
.accountDashContent.warning {
  border: 1px solid #B91C1C !important;
  background-color: #FEF2F233;
}

.accountDashBlock > .accountDashContent {
  border: 0.5px solid #e1e7ee;
}
.accountDashLimit {
  font-weight: 700;
}
.acProgressBg {
  height: 16px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #94A3B8;
  margin-top: 15px;
  overflow: hidden;
}
.acProgress {
  height: 16px;
  background-color: #10B981;
}
.warn {
  background-color: #F59E0B !important;
}
.acLegend {
  margin-top: 15px;
}
.acProgLegend {
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 50%;
  background-color: #10B981;
  margin-right: 5px;
}
.acRemLegend {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #94A3B8;
  margin-right: 5px;
}
.acLegendPart {
  width:49%;
  display: inline-block;
}
.acUpgrade {
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1.125em;
  text-decoration: none;
  color: #FFF;
  padding: 8px 12px;
  background-color: #41206C;
  border-radius: 10px;
  border: none !important;
  display: none;
  float: right;
  margin: 0px 39px 0px 0px;
}
.acCalloutBlock {
  padding:24px 30px;
  border-radius: 19px;
  margin: 36px;
}
.acCalloutBlock.delete {
  border: 1px solid #B91C1C;
  background-color: #FEF2F233;
}
.acCalloutIcon {
  float:left;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0px 10px 0px 0px;
}
.accountBlock .emphasized {
  font-weight: 700;
  margin-bottom: 0px;
}
.acCalloutBtn {
  font-size: 0.875em;
  font-weight: 500;
  line-height: 100%;
  text-decoration: none;
  color: #FFF;
  padding: 12px 40px 12px 18px;
  border-radius: 10px;
}
.acCalloutBtn.delete {
  background-color: #B91C1C;
  border: none !important;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) 50%;
  float: right;
}
.logoutButton {
  font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
  font-size: 0.875em;
  line-height: 100%;
  border-radius: 10px;
  background-color: #FFFFFF;
  color: #0F172A;
  border: 1px solid #0F172A;
  padding: 12px 18px;
  background-repeat: no-repeat;
  cursor: pointer;
  text-decoration: none;
  display:inline-block;
  margin-top: 16px;
}
.true, .false {
  display: none;
}
/* -- new account styles -- */
.account-tabs {
  width:100%;
  margin: 36px auto 0px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.account-tab {
  background-color:#E2E8F0;
  border-radius: 12px 12px 0px 0px;
  padding: 8px 16px 8px 12px;
  font-weight: 400;
  margin-right: 16px;
  cursor: pointer;
  width: 20%;
}
.account-tab.active {
  background-color: #fff;
  font-weight: 700;
}
.at-label {
  font-size: 1.125em;
  line-height: 1.25em;
  text-align: center;
  color: #0F172A;
  text-decoration: none;
  display: block;
  font-weight: 400;
}
.at-label.selected {
  font-weight: 700;
}
.account-tiles {
  width:100%;
  margin: 0px auto;
  background-color: #FFFFFF;
  padding: 1px 0px;
  min-height: 600px;
}
.account-tile {
  margin: 36px;
  padding: 24px 30px;
  border: 1px solid #CBD5E1;
  border-radius: 20px;
}
.account-tile > h4 {
  font-weight: 700;
  padding:0px;
  margin-top:0px;
  margin-bottom: 16px;
}
.account-tile > h4 > img {
  vertical-align: bottom;
  margin-right: 10px;
}
.account-row {
  margin-bottom: 16px;
  padding: 9px 0px 9px 0px;
  width: calc(50% - 8px);
  min-width: 200px;
  display: inline-block;
  margin-right: 0;
}
.account-fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.account-row > label {
  margin-top:0px;
  font-size: 1em;
  line-height: 1.250em;
  font-weight: 400;
  color:#64748B;
}
.account-row > .ap-display {
  font-size: 1.125em;
  line-height: 1.625em;
  font-weight: 400;
  color:#0F172A;
  min-height: 30px;
}
.ap-label {
  display: inline-block;
  font-size: 1em;
  line-height: 1.25em;
  font-weight: 700;
  color: #334155;
  margin-right:1em;
}
.ap-value {
  display: inline-block;
  font-size: 1em;
  line-height: 1.25em;
  font-weight: 400;
  color: #334155;
}
.account-controls {
  float: right;
}
input[type='button'].ap-action-button {
  font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 0.875em;
  line-height: 20px;
  border-radius: 10px;
  background-color: #0F172A;
  color: #F8FAFC;
  border: 0px;
  padding: 12px 40px 12px 18px;
  background-repeat: no-repeat;
  background-position: calc(100% - 14px) 50%;
  cursor: pointer;
  margin-left:24px;
}
.ap-edit > select {
  height: 52px;
  margin-top: 4px;
  padding: 0px 16px;
  border-radius: 10px;
  border: 1px solid #94A3B8;
  font-size: 1em;
  color: #000;
  width: 100%;
}
.account-smaller-half-tile {
  display:inline-block;
  width: 42%;
  min-height: 550px;
  margin: 36px 20px 36px 36px;
  border-radius: 8px;
  border: 1px solid #CBD5E1;
  vertical-align: top;
}
.account-smaller-half-tile img {
  display: block;
  margin: 52px auto 24px auto;
}
.account-smaller-half-tile h4, .account-larger-half-tile h4 {
  font-weight: 700;
  font-size: 1.125em;
  line-height: 1.250em;
  margin-left: 40px;
  margin-right: 40px;
}
.account-smaller-half-tile ul li {
  font-weight: 500;
  font-size: 0.875em;
  margin-left: 30px;
  margin-right: 42px;
}
.account-larger-half-tile {
  display:inline-block;
  width:calc(100% - 42% - 96px);
  min-height: 550px;
  margin: 36px 36px 36px 0px;
  border-radius: 8px;
  border: 1px solid #CBD5E1;
  vertical-align: top;
}
.account-password-block {
  padding:0px 16px;
  margin:55px auto 55px auto;
}
.account-password-block > input {
  width: calc(100% - 40px);
}
.account-password-block h3 {
  font-size: 1.5em;
  line-height: 1.875em;
  font-weight: 700;
  color: #0F172A;
  text-align: center;
  margin-bottom: 36px;
}
.account-lower-controls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;  
  justify-content: space-between;
  align-items: center;
  margin: 24px 0px 0px 0px;
}
.account-lower-controls a {
  text-decoration: none;
  color: #513AD1;
  font-size: 0.875em;
  line-height: 1.250em;
  font-weight: 500;
}
.account-plan-details-header {
  border:1px solid #CBD5E1;
  background-color: #D6E8EA;
  border-radius: 8px;
  padding:31px 20px;
  margin: 0px 40px;
  display: flex;
  justify-content: space-between;
}
.account-plan-details-header h4 {
  margin:0px;
}
.account-plan-details-body {
  margin: 0px 40px;
}
.account-plan-details-body p {
  color: #334155
}
.account-side-by-side {
  margin: 16px 40px 0px 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
}
.account-side-by-side-side {
  width: calc(50% - 8px);
}
.acListItem {
  display:inline-block;
}
.acCardImg {
  background-size: contain;
}
.acBillingImage {
  background-image: url('icons/icon-location.png');
  background-size:contain;
  background-repeat: no-repeat;
}
input[type='button'].acBillingEdit {
  padding: 12px 30px 12px 18px;
  background-image: url('icons/icon-edit-dark.png');
  background-repeat: no-repeat;
  background-position: 80% 50%;

}

/* ------------ TOAST STYLES -------------- */
.swal2-container {
  background-color: rgba(0,0,0,0) !important;
  width: auto !important;
  top:114px !important;
}

.swal2-backdrop-hide {
  display:none;
}

.swal2-popup {
  box-shadow: 0px 4px 6px -1px #0000001A;
  border-radius: 5px !important;
}

.swal2-popup.swal2-toast {
  padding: 28px 42px !important;
}

.swal2-popup.swal2-toast .swal2-icon {
  width: 1em;
  min-width: 1em;
  height: 1em;
  margin: 0 0 0 0;
}

.swal2-popup.swal2-toast .swal2-title {
  line-height: 130%;
}


.swal2-icon {
  border: none;
}

.swal2-title {
  font-weight: 400 !important;
}

.positoast {
  /*icon-announce-success*/
  width: 1em;
  height: 1em;
  background-image: url('icons/icon-announce-success.png');
  background-repeat: no-repeat;
  background-position: center center;
}

.correctitoast {
  width: 1em;
  height: 1em;
  background-image: url('icons/icon-announce-warn.png');
  background-repeat: no-repeat;
  background-position: center center;
}

.toastCongratsOn {
  opacity: 1 !important;
  transition: 250ms;
  border: 1px solid #047857 !important;
  background-color: #ECFDF5 !important;
  color:#064E3B !important;
}
.toastCongratsOff {
  opacity: 0 !important;
  transition: 250ms;
  border: 1px solid #047857 !important;
  background-color: #ECFDF5 !important;
  color:#064E3B !important;
}

.toastWarningOn {
  opacity: 1 !important;
  transition: 250ms;
  border: 1px solid #B91C1C !important;
  background-color: #FEF2F2 !important;
  color:#7F1D1D !important;
}
.toastWarningOff {
  opacity: 0 !important;
  transition: 250ms;
  border: 1px solid #047857 !important;
  background-color: #ECFDF5 !important;
  color:#7F1D1D !important;
}
.lengthIndicator {
  text-align: right;
  color: #0F172A;
}
.lengthIndicator.alert {
  color: #7F1D1D;
}
h5.linkpublish-type-title {
  margin: 24px 0px 3px 0px;
}
.linkpublish-type {
  padding: 24px 16px;
  border: 1px solid #94A3B8;
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.activityStream {
  width: 100%;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px;
  border: none;
  height: 2800px;
}
.createnew.header {
  display:block;
  background-color: #fff;
  margin: 0px;
  padding: 40px 0px 23px 0px;
  box-shadow: 0px 3px 12px 0px #B9B9B933;
}
.stepperheader {
  display:block;
  width: 85%;
  margin:0px auto;
}
.createnew > .stepperheader > a {
  text-decoration: none;
  color: #513AD1;
  font-size: 1.125rem;
}
.createnew > .stepperheader > h3 {
  font-size:1.5rem;
  line-height:100%;
  color: #0F172A;
  padding-bottom: 0px;
  margin: 13px 0px 0px 0px;
}
.createnew > .stepperheader > p {
  font-size: 1.125rem;
  color: #334155;
  line-height: 100%;
}
.steps {
  margin: 20px 0px 0px 0px;
  display:table;
}
.steps > hr {
  display: inline-block;
  height: 1px;
  width: 60px;
  vertical-align: middle;
  margin: 0px 15px 0px 15px;
}
.step {
  display: table-cell;
  text-align:left;
  vertical-align: middle;
}
.step > a {
  font-size: 1rem;
  line-height: 100%;
  font-weight: 700;
  text-decoration: none;
}
.indicator {
  width: 15px;
  height: 15px;
  padding: 5px;
  margin: -4px 8px 0px 0px;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  line-height: 90%;
  display: inline-block;
  vertical-align: middle;
}
.step.active a {
  color: #00606B;
}
.step.links.active a {
  color: #41206C;
}
.step.active .indicator {
  background-color: #00606B;
  color: #ffffff;
}
.step.links.active .indicator {
  background-color: #41206C;
  color: #ffffff;
}
.step.prior a {
  color: #0F172A;
}
.step.prior .indicator {
  background-color: #0F172A;
  color: #ffffff;
}
.step.upcoming a {
  color: #64748B;
}
.step.upcoming .indicator {
  background-color: #94A3B8;
  color: #ffffff;
}
.end {
  height: 200px;
}
.formContainer {
  width: 850px;
  padding: 35px 54px;
  border-radius: 8px;
  border: 1px solid #CBD5E1;
  background-color: #FFFFFF;
  margin: 48px auto 24px auto;
}
.formContainer h4 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 100%;
  color: #0F172A;
}
.inlineLabel {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 100% !important;
  color: #0F172A !important;
  display: inline;
  margin: 0px 150px 0px 8px;
}
.formContainer p, .formContainer h5, .fileName {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: #475569;
}
.formContainer label {
  font-size: 1rem;
  line-height: 100%;
  font-weight: 700;
  color: #0F172A;
  margin-bottom: 0px;
}
.formFooter {
  position: fixed;
  width: 100%;
  height: 97px;
  bottom: 0px;
  left: 0px;
  padding: 0px 0px;
  background-color:#ffffff;
}
.progressbarbackground {
  background-color: #DDDEE0;
  height: 8px;
}
.progressbarprogress {
  background-color: #0290A0;    
  height: 8px;
}
.progressbarprogress.links {
  background-color: #7D51B6;
}
.stepperFormControls {
  width: 85%;
  margin: 23px auto;
}
.stepperFormControls > input[type='submit'] {
  float:right;
  font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 20px;
  border-radius: 10px;
  background-color: #0F172A;
  color: #F8FAFC;
  border: 0px;
  padding: 12px 40px 12px 18px;
  background-repeat: no-repeat;
  background-position: calc(100% - 14px) 50%;
  cursor: pointer;
}
.stepperFormControls > input[type='button'] {
  float:left;
  font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 20px;
  border-radius: 10px;
  background-color: #FFFFFF;
  color: #0F172A;
  border: 1px solid #0F172A;
  padding: 12px 18px 12px 40px;
  margin-right:12px;
  background-repeat: no-repeat;
  background-position: 15px 50%;
  cursor: pointer;
}
.stepperFormControls > input[type='button'].cancel {
  float:left;
  font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 20px;
  border-radius: 10px;
  background-color: #FFFFFF;
  color: #0F172A;
  border: 1px solid #0F172A;
  padding: 12px 18px;
  background-repeat: no-repeat;
  cursor: pointer;
}
.fieldListHeadings > label {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 100%;
  display:inline-block;
}
.ordercol {
  width: 110px;
}
.namecol {
  width: 132px;
}
.labelcol {
  width: 365px;
}
.visiblecol {
  width: 113px;
}
.requiredcol {
  width: auto;
}
.fieldDroppable {
 list-style: none;
 counter-reset: item;
 width: 100%;
 padding: 0px;
 vertical-align: center;
}
.fieldDroppable li {
  border: 1px solid #333;
  border-radius: 6px;
  background-color:#fff;
  padding: 2px;
  margin: 16px 0px 0px 0px;
  height: 62px;
  counter-increment: item;
  display: block;
  vertical-align: middle;
}
.fieldDroppable li:before {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 100%;
  color: #030E31;
  padding-left: 10px;
  content: counter(item) ". ";
  width: 60px;
  display: inline-block;
  text-align: left;
  margin-top: 16px;
}
.fieldName {
  display:inline-block;
  width: 130px;
}
.fieldlabelInput {
  width: 260px !important;
  margin: 4px 0px 0px 0px !important;
}
.fieldDroppable li > .visSwitch {
  top: 7px;
  left: 62px;
}
.visSwitch {
  top: 3px;
  left: 0px;
}
.reqSwitch {
  top: 7px;
  left: 133px;
}
select {
  height: 52px;
  margin: 14px 0px 0px 0px;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #94A3B8;
  font-size: 0.875rem;
  line-height: 100%;
  color: #0F172A;
  min-width: 420px;
  display: block;
}
select option {
  height:44px;
  background-color: #FFF;
  font-size: 0.875rem;
  line-height:100%;
  color:#0F172A;
  font-weight: 400;
}
select option:hover {
  background-color: #F9FFAA;
  font-weight: 700;
}
.hideme {
  display: none;
}
a.linkpreview {
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  border-radius: 10px;
  color: #0F172A;
  border: 1px solid #0F172A;
  background-color: #ffffff;
  padding: 12px 43px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin: 25px 12px 0px 12px;
  height: 22px;
}
a.linkpreview:focus-visible {
  outline: 2px solid yellow;
}
a.linkunpublish {
  font-weight: 700;
  font-size: 1rem;
  line-height: 20px;
  border-radius: 10px;
  color: #0F172A;
  border: none;
  padding: 12px 43px;
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
  margin: 25px 0px 0px 0px;
  height: 22px;
}
a.linkunpublish:focus-visible {
  outline: 2px solid yellow;
}
a.linkpublish {
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  border-radius: 10px;
  color: #F8FAFC;
  border: none;
  background-color: #41206C;
  padding: 12px 43px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin: 25px 12px 0px 12px;
  height: 22px;
}
a.linkpublish:focus-visible {
  outline: 2px solid yellow;
}
.moveHandles {
  width: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 25px;
}
.moveDown, .moveUp {
  width: 12px;
}
.moveUp img {
  vertical-align: bottom;
}
.moveDown img {
  vertical-align: top;
}
.radio-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}