/* login style */ 
h1.login {
    font-family: 'Lato Black', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 2.375rem;
    font-weight: 900;
    line-height: 44px;
    text-align: left;
    color: #353E5A
  }
  
.logo {
    position:absolute;
    width: 134.51px;
    height: 80px;
    top: 30px;
    left: 30px;
}

.loginErrorMessage {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #353E5A;
    margin-top: 20px;
}

.loginForm {
    width: 376px;
    height: 446px;
    margin-top: 27vh;
    margin-left: calc(25vw - 188px);
}

.instruction {
    font-size: 1rem;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #353E5A;
}


label[for='rememberme'] {
    display: inline-block;
    width: 120px;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    margin-left: 10px;
    position: relative;
    bottom: 6px;
    color: #353E5A;
}

.loginForm > input[type='checkbox'] {
    width: 24px;
    height: 24px;
    margin-top:20px;
}

.loginForm > input[type='text'],.loginForm > input[type='password'] {
    width: calc(100% - 40px);
    height: 52px;
    margin-top: 4px;
    padding: 0px 20px;
    border-radius: 10px;
    border: 1px solid #94A3B8;
    font-size: 1rem;
    color:#000;
}
.loginForm > input[type='text']:focus,.loginForm > input[type='password']:focus {
    border: 2px solid #000;
    outline: none;
}
.loginForm > input[type='text']::placeholder,.loginForm > input[type='password']::placeholder {
    font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: #475569;
}

.loginForm > input[type='submit'], .loginBtn {
    font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    line-height: 20px;
    width: 100%;
    height: 44px;
    margin-top: 20px;
    border-radius: 10px;
    background-color: #1E293B;
    color: #F8FAFC;
    border: 0px;
    background-image: none;
}

.loginForm > div > input[type='submit'] {
    font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    line-height: 20px;
    width: 100%;
    height: 44px;
    margin-top: 20px;
    border-radius: 10px;
    background-color: #1E293B;
    color: #F8FAFC;
    border: 0px;
    background-image: none;
}

.loginForm > input[type='password'].badentry:focus {
    border-color: red !important;
    outline: none;
}

.badentry {
    border-color: red !important;
}

.loginForm > input[type='password'].goodentry:focus, .goodentry {
    border-color: green !important;
    outline: none;
}

.goodentry {
    border-color: green !important;
}

.loginError {
    border: 0.5px solid #B91C1C;
    background: #FEF2F2;
    padding: 20px;
    border-radius: 8px;
    color: #0F172A
}

.loginSuccess {
    border: 0.5px solid #047857;
    background: #ECFDF5;
    padding: 20px;
    border-radius: 8px;
    color: #064E3B
}

.forgotpass {
    font-weight: 700;
    font-size: 1rem;
    line-height: 20px;
    float: right;
    margin-top: 20px;
    color: #513AD1;
}

.loginlink {
    font-weight: 700;
    font-size: 1rem;
    line-height: 20px;
    float: right;
    margin-top: 20px;
    color: #513AD1;
}

.quotePanel {
    background-color: #00606B;
    border-radius: 40px;
    width: calc(50vw - 80px);
    padding: 20px;
    height: calc(100vh - 80px);
    color: #fff;
    position:absolute;
    top:20px;
    right:20px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.profilepic {
    /*width: 277px;
    height: 30%;*/
    width: 50%;
    max-width: 277px;
    border-radius: 50%;
}

.quote {
    font-size: 3.015rem;
    font-weight: 700;
    line-height: 72px;
    text-align: center;
    max-width: 640px;
    margin: 0px auto;
    text-wrap: balance
}

.byline {
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 34px;
    text-align: center;
    max-width: 640px;
    margin: 20px auto 0px;
    color: #F8FAFC;
    text-wrap: balance
}

.patentMessage.login {
    display:inline-block;
    position: relative;
    top: 120px;
    left: 39px;
    width: 100px;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 700;
    padding:6px 8px;
    background-color: #AEDFE4;
    color: #0F172A;
    border-radius: 6px;
}

@media only screen and (max-height: 645px) {
    .quotePanel {
        display:none;
    }
    .loginForm {
        width:90%;
        margin: 27vh auto 0px;
    }
}

@media only screen and (max-width: 900px) {
    .quotePanel {
        display:none;
    }
    .loginForm {
        width:90%;
        margin: 27vh auto 0px;
    }
}

@media only screen and (max-height: 450px) {
    .quotePanel {
        display:none;
    }
    .loginForm {
        width:90%;
        margin-left: 5%;
        margin-top: 130px;
    }
}