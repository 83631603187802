/* Navbar CSS */
.navbar {
    height: 80px;
    width: 306px;
    margin: 0px 1px;
    display: block;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    border-bottom: 1px solid #CDCFD6;
}

.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
    color: #000;
    text-decoration: none;
}

.nav-menu {
    display: block;
    background-color: #ffffff;
    width: 310px;
    justify-content: center;
    left: 0px;
    overflow: hidden;
    transition: 250ms;
    box-shadow: 0px 1px 3px 0px #0000002C;
    border-radius: 0px 0px 20px 0px;
    position: absolute;
    z-index: 200;
}

.navbar-title {
    display: table-cell;
    vertical-align: middle;
    height: 80px;
    color: #0F172A;
    font-weight: 700;
    font-size: 1.75rem;
    padding: 0px 24px;
    opacity: 100;
    transition: 250ms;
}

.nav-menu.active {
    /*left: 0;*/
    width: 72px;
    margin-right: calc((100vw - (1064px + 72px)) / 2);
    transition: 150ms;
}

.nav-menu ul > li.nav-text, .nav-menu ul > li.nav-folder {
    padding: 8px 0px 8px 16px;
    transition: 150ms;
}
.nav-menu ul > li.nav-folder {
    max-width: 272px;
}

.nav-menu ul > li.nav-text-child {
    padding: 8px 0px 0px 8px;
    transition: 150ms;
}

.nav-menu.active > .navbar {
    width: 72px;
    transition: 150ms;
}

.nav-menu.active > .navbar > .menu-bars > .menu-close {
    display: none;
    transition: 150ms;
}
.nav-menu.active > .navbar > .menu-bars > .menu-open {
    display: block;
    transition: 150ms;
}
.nav-menu.active > .nav-menu-items > .navbar-title {
    display: none;
    opacity: 0;
    transition: 150ms;
}
.nav-menu.active > .nav-menu-items {
    padding-top: 80px;
}

.nav-menu.active ul > li.nav-text, .nav-menu.active ul > li.nav-folder, .nav-menu.active ul > li.nav-text-child {
    padding: 8px 0px 8px 0px;
    transition: 150ms;
}

.nav-menu.active div > a > span {
    transition: 150ms;
}

.nav-menu.active ul > li > a > span:nth-child(2), .nav-menu.active ul > li.nav-folder > span > a > span:nth-child(2) {
    opacity: 0;
    transition: 150ms;
}

.nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    color: #0F172A;
    font-size: 1.125rem;
    font-weight: 400;
    min-width: 272px;
    height: 100%;
    display: flex;
    padding:0px 2px;
    align-items: center;
    border-radius: 10px;
}

.nav-text a:hover {
    background-color: #fcffce;
}

.nav-text.active > a {
    font-weight: 900;
    background-color: #F9FFAA;
}

.nav-text.active > a > .icons-menu {
    border-left: 2px solid #0F172A;
}

.nav-folder {
    list-style: none;
}

.nav-folder > span > a {
    text-decoration: none;
    color: #0F172A;
    font-size: 1.125rem;
    font-weight: 400;
    min-width: 272px;
    height: 60px;
    display: flex;
    padding:0px 2px;
    align-items: center;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: calc(100% - 24px) 26px;
    background-image: url('./icons/icon-chev-down.png');
    background-size: 14px 9px;
}
.nav-folder.open > span > a {
    background-repeat: no-repeat;
    background-position: calc(100% - 24px) 26px;
    background-image: url('./icons/icon-chev-up.png');
    background-size: 14px 9px;
}
.nav-folder > span > a span {
    margin-bottom: 3px;
}

.nav-folder > span > a:hover {
    background-color: #fcffce;
}

.nav-folder.active > span > a {
    font-weight: 900;
    background-color: #F9FFAA;
}
.nav-folder.active.open > span > a {
    background-repeat: no-repeat;
    background-position: calc(100% - 24px) 26px;
    background-image: url('./icons/icon-chev-up.png');
    background-size: 14px 9px;
}
.nav-folder.active > span > a > .icons-menu {
    border-left: 2px solid #0F172A;
}

.folder-open {
    display: block;
    background-color: #F1F5F9;
    border-radius: 10px;
}

.children li a span {
    padding-left:30px;
}

.nav-text-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    height: 60px;
}

.nav-text-child a {
    text-decoration: none;
    color: #0F172A;
    font-size: 1.125rem;
    font-weight: 400;
    min-width: 253px;
    height: 100%;
    display: flex;
    padding:0px 2px;
    align-items: center;
    border-radius: 10px;
}

.nav-text-child a:hover {
    background-color: #CBD5E1;
}

.nav-text-child.active > a {
    font-weight: 900;
    background-color: #475569;
    color: #F8FAFC;
}

.nav-text-child.active > a:focus-visible {
    outline: 2px solid yellow;
}

.nav-text-child.active > a > .icons-menu {
    border-left: 2px solid #0F172A;
}

.nav-menu-items {
    width: 100%;
    margin: 0px;
    padding: 0px;
}

.navbar-toggle {
    background-color: #ffffff;
    width: 80%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.patentMessage.menu {
    display: block;
    width: 104px;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 700;
    padding: 6px 8px;
    background-color: #E8E0F3;
    color: #0F172A;
    border-radius: 6px;
    margin: 120px auto 16px auto;
}

.version-number.menu {
    text-align: center;
    width: 310px;
    font-size: 0.875rem;
    font-weight: 500;
    color: #0F172A;
    margin-bottom: 24px;

}

.nav-menu.active > .patentMessage.menu, .nav-menu.active > .version-number.menu {
    display:none;
}


/*====================== FOLDER SPECIFIC ==============================*/

.children {
    padding-bottom: 8px;
    display:none;
}

.children.active {
    display:block;
}