/*@media screen and (max-width: 1160px), screen and (max-width: 1000px) and (max-height: 450px) {*/
@media screen and (max-width: 1256px) {
    .accountBlock {
        width: 100% !important;
    }
}

/* smaller screens */
@media screen and (max-width: 1160px) {

    .listtable {
        background-color: transparent !important;
    }
    .listtable tbody {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 16px;
    }
    .listtable tr.header {
        display:none;
    }
    .listtable > tbody > tr {
        display:block;
        width: calc(50% - 32px);
        min-width: 220px;
        height: auto !important;
        border: 1px solid #94A3B8;
        border-radius: 12px;
        background-color: #FFF;
    }
    .listtable > tbody > tr:hover {
        background-color: #FFF !important;
    }
    .listtable > tbody > tr > td {
        display:inline-block;
        height: auto !important;
        padding-left:0px !important;
    }
    .listmenu > button {
        margin: 0px;
    }
    .listcell.created, .listcell.modified, .listcell.ID, .listcell.city, .listcell.state, .listcell.created {
        display:none;
    }
    .listcell.brand_logo > a.logolink > img {
        height: 16px !important;
    }
    .listcell.name {
        width:100%;
        margin: 6px 0px 8px 0px;
        padding-top: 8px;
        border-top: 1px solid #94A3B8;
        font-size:larger;
        font-weight: 700;
    }
    .listcell.lrs_xcl {
        margin-top:12px;
        min-width: 100px;
    }
    .listcell.listcell.lrs_xcl >  div {
        display: inline-block;
        vertical-align: middle;
    }
    .listcell.lrs_xcl:after {
        content: "XCL LRS"; 
    }
    .listcell.brand_logo.profile, .listcell.company, .listcell.email, .listcell.plan {
        width: 100%;
    }
    .listcell.brand_logo.profile > a > img {
        height:48px !important;
        margin: 0px auto !important;
    }
    
    .listmenu {
        float:right;
    }
    .listRow {
        padding: 8px;
    }
    .listcell.published {
        margin: 4px 0px 0px 0px;
    }
    .listcell.filetype {
        margin: 12px 0px 0px 0px;
    }
    .createNewBtn {
        float: none !important;
        position: static !important;
        margin-top: 12px;
    }
    .emptyTable {
        padding: 16px !important;
        height: auto !important;
    }
    .emptyTable > img {
        margin-top: 0px !important;
    }
    .emptyRow {
        width: 100% !important;
    }
    .stophover {
        background-color: transparent !important;
    }
    .contentOuter {
        padding-left: 119px !important;
    }
    .homeSbS {
        flex-direction: column;
    }
    .homeTile {
        width: auto !important;
    }
    .listmenu > button {
        font-size: 0px !important;
    }
    .activityStream {
        border-radius: 0px;
        border: none;
        padding:0px;
        margin: 0px;
        overflow: hidden;
    }
    .homeSbS {
        flex-direction: column !important;
    }
    .homeTile, .homeTileLarge {
        height:auto !important;
        padding-bottom:24px !important;
        background-position: bottom right !important;
        background-size: 100px;
    }
    .account-side-by-side-side {
        margin-top: 16px;
        width:100%;
    }
}

@media screen and (max-width: 1000px) {
    .formContainer {
        width: auto;
        margin: 48px 12px 24px 12px !important;
    }
    .fieldscustomizer > .fieldsContainer {
        min-width: 848px;
    }
    .fieldscustomizer {
        background-color: #fff;
        padding:0px 12px 12px 12px;
        margin-top:12px;
        border: 1px solid #94A3B8;
        border-radius: 8px;
        overflow:scroll;
    }
}

@media screen and (max-width: 930px) {
    /* special for dropzone */
    .dropzone-errors {
        min-height: 0px !important;
    }

}


@media screen and (max-width: 880px) {
    /* this tier should have menu closed by default */
    .helpPageTile {
        padding: 12px 12px 12px 12px !important;

    }
    .helpPageTile > h3 {
        font-size: 1.250em;
        line-height: 120%;
    }
    .helpInfo {
        display: flex !important;
        flex-direction: column;
        align-items: center;
    }
    .helpInfo > .titleBlock {
        width:100%;
        min-width:0%;
        text-align: center;
    }
    .helpInfo > .formBlock {
        width:100%;
    }
    .helpInfo > .formBlock > .formColumn, .helpInfo > .formBlock > .formColumn.column2 {
        display:block;
        width:100%;
        margin-left:0px
    }
    .helpInfo > .titleBlock > h2 {
        font-size: 1.250em;
        line-height: 130%;
        padding: 0px;
        margin: 0px;
    }
    .helpInfo > .titleBlock > img {
        display: none;
    }
    .contactInfo {
        padding:12px;
        font-size: 0.750em;
    }
    .contentOuterHome, .contentOuterHome.openNav {
        padding-left: 90px !important;
        padding-right: 16px !important;
    }
    .account-tile {
        font-size:0.875em;
        margin: 12px;
        padding: 12px 16px
    }
    .account-tiles {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .account-smaller-half-tile, .account-larger-half-tile {
        width: auto;
        margin: 12px;
        min-height: auto;
    }
    .account-smaller-half-tile img {
        margin: 12px auto;
    }
    .account-password-block {
        margin: 12px auto;
    }
    .account-password-block > h3 {
        font-size: 1.250em;
        margin-bottom:12px;
    }
    .acListItem.plan, .acListItem.invoicenum {
        display:none;
    }
    .acListItem.date {
        width: calc(60% - 50px) !important;
    }
    .acListItem.amount {
        width: calc(40% - 50px) !important;
    }
    .acListItem.controls {
        width: 100px !important;
    }
    .lpControls {
        display: none !important;
    }
    .lpContainer {
        display: none !important;
    }
    .ddconntainer {
        position: static !important;
    }
    .ddbutton {
        min-width: auto !important;
    }
    .formContainer.preview {
        display:none;
    }
    .fileName {
        overflow: hidden;
    }
}

@media screen and (max-width: 700px) {
    /* this tier should have menu closed by default */
    .account-controls {
        float: none;
        margin: 16px 0px 0px 0px;
    }
    .account-controls > input {
        margin-left:0px !important;
    }
    .account-tab {
        font-size:0.750em;
        padding: 6px 10px;
        margin-right: 4px;
    }
    .account-tile > h4, .accountDashBlock > h5, .account-side-by-side {
        margin-left:12px !important;
        margin-right:12px !important;
    }
    .accountDashContent {
        margin-left:12px !important;
        margin-right:12px !important;
        padding: 12px;
    }
    .account-side-by-side-side > .accountDashContent {
        margin-left:0px !important;
        margin-right:0px !important;
        padding: 12px;
    }
    .steps > hr {
        display: none;
    }
    .steps > .step {
        display: block;
        margin:6px 0px;
    }
    .swal2-container {
        width: 90% !important;
    }
    .acCalloutBtn.delete {
        margin-top: 12px;
        font-size:0.750em;
    }

}

@media screen and (max-width: 610px) {
    /* this tier should have menu closed by default */
    #helpLinkObj {
        display:none;
    }
    .acLegend {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: row;
    }
    .acLegendPart {
        width: auto;
    }
    .account-half-content > h4, .account-half-content > .acUpgrade, .account-half-content > .accountDashBlock.internal, .account-half-content > .account-plan-details-header, .account-half-content > .account-plan-details-body {
        margin-left: 12px;
        margin-right: 12px;
    }
    .formContainer {
        padding: 24px 12px;
        margin: 24px 12px;
    }
    .radio-options {
        justify-content: space-between;
    }
    .radio-options > div > label {
        margin: 0px 10px 0px 8px
    }
    .stepperFormControls > input[type='button'], .stepperFormControls > input[type='submit'] {
        font-size: 0px;
    }
    .linktext {
        width:calc(100% - 42px) !important;
        margin-bottom: 8px;
    }
    .linkpublish-type {
        flex-direction: column;
    }
    .linkpublish-type > div {
        display:flex;
        flex-direction: column;
        margin-left:0px !important;
    }
}

@media screen and (max-width: 500px) {
    /* this tier should have menu closed by default */
    .contentOuter, .contentOuter.openNav {
        padding-left: 90px !important; 
        padding-right: 16px;
    }
    h4 {
        font-size: 1em;
    }
    h1 {
        font-size: 1.250em;
        line-height: 120%;
    }
    h1 > img {
        height:1.250em;
        margin-right:4px;
    }
    .myAccountLink {
        font-size : 0px !important;
        right: 16px !important;
    }
    .contentOuterHome > h1 > img {
        display: none;
    }
    .smallInput.brandingcolor {
        width: calc(100% - 42px) !important;
        margin-left: 0px !important;
        margin-top: 16px !important;
    }
    .formControls.admin {
        width: calc(100% - 140px);
        display:flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .adminsubmit {
        font-size: 0px !important;
    }

}

@media screen and (max-width: 445px) {
    /* special for dropzone */
    .dropzone {
        width: 100% !important;
    }
    .fileNameBadge {
        left: calc(50% - 23px) !important;
    }
}

