.contentOuterHome {
  display: block;
  /*margin: 48px auto 0px;
  max-width: 800px;
  width: 100%;*/
  padding: 0px 42px 120px 119px;
}

.contentOuterHome.openNav {
  /*padding-left: 315px;*/
  padding-left: 357px;
}

a.letsgo {
  display: inline-block;
  font-weight: 400;
  font-size: 1em;
  line-height: 20px;
  border-radius: 10px;
  color: #0F172A;
  background-color: #fff;
  padding: 14px 54px 14px 20px;
  background-image: url('icons/icon-arrowright.png');
  background-repeat: no-repeat;
  background-position: calc(100% - 22px) 15px;
  text-decoration: none;
}

a.letsgolt {
  display: inline-block;
  font-weight: 400;
  font-size: 1em;
  line-height: 20px;
  border-radius: 10px;
  color: #0F172A;
  background-color: #fff;
  border: 1px solid #0F172A;
  padding: 14px 54px 14px 20px;
  background-image: url('icons/icon-arrowright.png');
  background-repeat: no-repeat;
  background-position: calc(100% - 22px) 15px;
  text-decoration: none;
}

.helpLink {
  position:absolute;
  top: 27px;
  height: 30px;
  right: 266px;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  text-decoration: none;
}

.myAccountLink {
  position:absolute;
  top: 27px;
  height: 30px;
  right: 36px;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  text-decoration: none;
}

.helpLink.active, .myAccountLink.active {
  border-bottom: 2px solid #fff;
}

.myAccountLinkIcon {
  vertical-align: bottom;
  margin: 0px 10px 0px 0px;
}

.homeTileLarge {
  display: block;
  padding: 48px 30px 48px 30px;
  border-radius: 25px;
  margin: 32px 0px 0px 0px;
  text-decoration: none;
  background-repeat: no-repeat;
}

.homeTileLarge > p {
  width: 65%
}

.recentFiles, .helptile {
  background-position: bottom right;
  background-color: #fff;
}

.admintile {
  background-position: 100% 70px;
  background-color: #E2E8F0;
}

.admintile, .helptile {
  color: #0F172A;
}

.recentFilesTiles {
  margin: 32px 0px 0px 0px;
}

.homeTile {
  /*display: inline-table;
  width: 43%;*/
  height: 288px;
  border-radius: 25px;
  background-repeat: no-repeat;
  background-position: 100% 151px;
}

.homeSbS {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  margin: -22px 0px 0px 0px;
}

.linkprofiletile > img {
  margin: 27px 0px 0px 0px;
}

.linktile > img {
  margin: 52px 0px 0px 0px;
}

.linkprofiletile {
  margin: 24px 0px 0px 0px;
  padding: 48px 30px 0px 30px;
  background-color: #00606B;
  text-decoration: none;
  width: 100%;
}

.linkprofiletile > h3, .linkprofiletile > p {
  color: #F8FAFC;
}

.linktile {
  margin: 24px 0px 0px 0px;
  padding: 48px 30px 0px 30px;
  background-color: #41206C;
  text-decoration: none;
  width: 100%;
}

.linktile > h3, .linktile > p {
  color: #fff;
}

/*@media screen and (max-width: 880px) {

  .contentOuterHome, .contentOuterHome.openNav {
    padding-left: 90px;
    padding-right: 16px;
  }

  .homeSbS {
      flex-direction: column;
  }

}

@media screen and (max-width: 500px) {
  .contentOuterHome > h1 > img {
    display: none;
  }
}*/