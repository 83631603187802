.faqList {
    padding:0px;
    list-style-position: inside;
    
}

.faqList > button {
    padding:12px 50px 0px 12px;
    border-radius: 10px;
    background-color: #E8E0F3;
    margin-bottom:24px;
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) 21px;
    background-size:12px 9px;
}

.faqList > button > button {
    display: inline;
}
.faqList > button::marker {
    font-weight: bold;
}
.faqList > button > p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    color: #0F172A;
    background-color: #fff;
    margin:12px -50px 0px -12px;
    padding: 12px 16px 24px 16px;
    border-radius: 0px 0px 10px 10px;
}
.faqList > button > h5 {
    padding:0px;
    margin:0px;
}
.faqItem {
    width:100%;
    border: none;
    text-align: left;
}

.helpPageTile {
    display: block;
    padding: 20px 40px 40px 40px;
    background-color: #fff;
    border-radius: 30px;
    border-radius: 25px;
    margin: 37px 0px 0px 0px;
}

.titleBlock {
    display: table-cell;
    min-width: 350px;
    width:27%;
}

.titleBlock > h2 {
    margin: 20px 36px 36px 16px;
}

.titleEmphasis {
    display: inline-block;
    color:#7D51B6;
}

.titleBlock > img {
    height:222px;
    margin: 0px 36px 70px auto;
}

.formBlock {
    width: 68%;
    display: table-cell;
}
