.obDarkBlanket {
    position: fixed;
    top:0px;
    left:0px;
    width: 100vw;
    height: 100vh;
    background-color: #040716A6;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.obContainer {
    max-width: 1000px;
    width: 90vw;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 16px;
    padding: 24px 48px 64px;
    /*box-shadow: -2px 20px 32px 0px #6061703D;*/
    box-shadow: 0px 2px 16px 0px #28293D1F;
}

.obContainer h2 {
    font-size:2.125rem;

}

.obContainer h4 {
    text-align: center;
}

.obContainer img.congrats {
    margin: 16px 0px 14px 24px;
}

.obContainer img.obStepGraphic {
    margin: 0px auto 16px auto;
    width: 100%;
}

.obNavContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.obContainer input[type='button'].next, .obNavContainer input[type='button'].next {
    color: #F8FAFC;
    margin: 24px 0px 0px 0px;
    background-color: #0F172A;
    border: none;
    background-position: calc(100% - 14px) 50%;
    background-repeat: no-repeat;
    padding: 12px 40px 12px 18px;
    cursor: pointer;
}

.obContainer input[type='button'].done, .obNavContainer input[type='button'].done {
    color: #F8FAFC;
    margin: 24px 0px 0px 0px;
    background-color: #0F172A;
    border: none;
    padding: 12px 18px 12px 18px;
    cursor: pointer;
}

.obNavContainer input[type='button'].back {
    margin: 24px 0px 0px 0px;
    background-color: #FFFFFF;
    color: #0F172A;
    border: 1px solid #0F172A;
    padding: 12px 18px 12px 40px;
    background-repeat: no-repeat;
    background-position: 15px 50%;    
    cursor: pointer;
}

.onCloseButton {
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    float: right;
    top: 20px;
    right: 20px;
    margin: -5px -31px 0px 0px;
}

.obStepperControls {
    width: 70px;
    margin: 0px auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.obStep {
    width: 10px;
    height: 10px;
    background-color: #00606B;
    border-radius: 50%;
    cursor: pointer;
}

.obStep.active {
    width: 16px;
    height: 16px;
    background-color: #0290A0;
    border-radius: 50%;
}

